import type { GGrid, GAd, TGridProps } from '@hubcms/domain-teaser-grid';

import { createCbaAd } from './createCbaAd';

export function createAdItems(isCbaAd: boolean, adFormat: string, gridProps: TGridProps): GAd | GGrid {
  if (isCbaAd) {
    return createCbaAd(adFormat, gridProps);
  }

  return {
    type: 'ad',
    data: { adSlot: 'b', adFormat, isSticky: true },
    gridProps,
  };
}
