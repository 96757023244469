import type { CustomizableStoryblockGroup } from '@hubcms/domain-cook';
import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';
import type { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';
import { createCustomizableGroupAreaOptions } from './createCustomizableGroupAreaOptions';

export const createCustomizableGroupOptions: CreateStoryblockOptionsFn<CustomizableGroupOptions, CustomizableStoryblockGroup> = ({
  group,
  sectionParams,
}) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'CustomizableGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),

    title: groupOptions.groupTitle,
    actionLink: getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink'),
    actionText: sectionParams['section.group.readmore.text'],
    areaOnePosition: isAreaOnePosition(groupOptions['areaOnePosition']) ? groupOptions['areaOnePosition'] : 'left',

    ...createCustomizableGroupAreaOptions(group, sectionParams),
  };
};

function isAreaOnePosition(position: string): position is CustomizableGroupOptions['areaOnePosition'] {
  return position === 'left' || position === 'middle' || position === 'top';
}
