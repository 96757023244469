import { createTitle, type TTeaserGrid } from '@hubcms/domain-teaser-grid';
import type { ArticleGroupOptions } from '@hubcms/domain-storyblock';

import { appendTrackingData } from '../../internal/appendTrackingData';
import { hideGridOnMobile } from '../../internal/hideGridItemOnMobile';
import { createListData } from '../../internal/createListData';
import { createListItems } from '../../internal/createListItems';
import type { ListOptions } from '../../domain/list-options';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';

export const mapArticleGroup: MapStoryblockFn<ArticleGroupOptions> = (storyblockOptions, teaserAreas) => {
  const items = teaserAreas.groupArea;

  const groupTitle = createTitle(storyblockOptions.title, storyblockOptions.actionLink, storyblockOptions.actionText);
  const hideArticleGroupOnMobile = storyblockOptions.isHiddenOnMobile;

  if (groupTitle) {
    groupTitle.data.headerLevel = 2;
    groupTitle.data.size = 'sm';
  }

  const listOptions: ListOptions = {
    ...storyblockOptions,
    isHighlighted: false,
    sliderTeaserOrientation: 'horizontal',
    sliderDefaultMinWidth: 311,
  };

  const listItems = createListItems(items, listOptions).map((gridItem, idx) => {
    if (gridItem.type === 'html') {
      return gridItem;
    }
    return appendTrackingData(gridItem, {
      clickitemblock: storyblockOptions.title,
      clickitemposition: idx + 1,
    });
  });

  const listArea = createListData(listItems, listOptions);

  if (storyblockOptions.hasImages) {
    listArea.data.verticalAlign = 'middle';
  } else {
    listArea.data.mdTwoColumns = true;
  }

  let gridData: TTeaserGrid = {
    title: groupTitle,
    items: [listArea],
    gridColumns: 1,
    gridColumnsMd: 1,
    gridColumnsLg: 1,
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
  };

  if (hideArticleGroupOnMobile) {
    gridData = hideGridOnMobile(gridData);
  }

  return gridData;
};
