import { defaultTeaserImageRatio, sponsoredContentTeaserIsHighlighted } from '@hubcms/brand';

import {
  type ArticleTeaserData,
  type PlaceholderTeaserData,
  type TeaserData,
  isHtmlTeaserData,
  isNewsletterTeaserData,
  isPlaceholderTeaserData,
  isRssListTeaserData,
} from '@hubcms/domain-teaser';
import type { GDefaultTeaser, GHtml, GTextOnImageTeaser, TGridProps, TTeaserGridItem } from '@hubcms/domain-teaser-grid';
import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';

import type { BaseParams } from './types';
import { createHtmlGridItem } from '../../createHtmlGridItem';

type TeaserOptions = {
  isDefaultTeaser: boolean;
  hasSponsoredThemeOnTeaserLevel: boolean;
  isTeaserHighlighted: boolean;
  isPositionTop: boolean;
  isPositionLeft: boolean;
  isPositionMiddle: boolean;
};

type AreaOneOptions = CustomizableGroupOptions['areaOneOptions'];

export function mapToAreaOneItems(
  teaserDataList: TeaserData[],
  areaOneOptions: AreaOneOptions,
  params: BaseParams,
): Array<TTeaserGridItem<string, unknown>> {
  const teaserData = teaserDataList[0];
  if (!teaserData || isRssListTeaserData(teaserData) || isNewsletterTeaserData(teaserData)) {
    return [];
  }

  const teaserOptions: TeaserOptions = {
    isDefaultTeaser: areaOneOptions.isDefaultTeaser,
    hasSponsoredThemeOnTeaserLevel: false,
    isTeaserHighlighted: areaOneOptions.isHighlighted,
    isPositionTop: params.areaOnePosition === 'top',
    isPositionLeft: params.areaOnePosition === 'left',
    isPositionMiddle: params.areaOnePosition === 'middle',
  };

  let teaser: GDefaultTeaser | GTextOnImageTeaser | GHtml;
  if (isHtmlTeaserData(teaserData)) {
    teaser = createHtmlGridItem(teaserData);
    teaserOptions.isTeaserHighlighted = false;
  } else {
    const hasSponsoredThemeOnTeaserLevel = !isPlaceholderTeaserData(teaserData) && teaserData.theme === 'sponsored-content';
    teaserOptions.hasSponsoredThemeOnTeaserLevel = hasSponsoredThemeOnTeaserLevel;
    teaserOptions.isTeaserHighlighted ||= hasSponsoredThemeOnTeaserLevel && sponsoredContentTeaserIsHighlighted;

    if (areaOneOptions.isDefaultTeaser) {
      teaser = mapToDefaultTeaser(teaserData, areaOneOptions, teaserOptions);
    } else {
      teaser = mapToTextOnImageTeaser(teaserData, areaOneOptions, teaserOptions);
    }
  }

  teaser.gridProps = createItemGridProps(teaser, params, teaserOptions);

  return [teaser];
}

function mapToDefaultTeaser(
  teaserData: ArticleTeaserData | PlaceholderTeaserData,
  { hasIntroDesktop, hasIntroMobile }: AreaOneOptions,
  { isPositionTop, isTeaserHighlighted }: TeaserOptions,
) {
  const teaser: GDefaultTeaser = {
    type: 'default-teaser',
    data: {
      teaserData,
      hasIntro: {
        xs: hasIntroMobile,
        sm: hasIntroMobile,
        md: hasIntroMobile,
        lg: hasIntroDesktop,
        xl: hasIntroDesktop,
      },
      hasDateTime: false,
      isPriority: true,
      headerLevel: 2,
      isHighlighted: isTeaserHighlighted,
      imageSizes: isPositionTop ? { sm: '100vw', lg: '848px' } : { sm: '100vw', lg: '632px' },
      size: 'lg',
      orientation: 'vertical',
      hasInset: isTeaserHighlighted || {
        xs: true,
        lg: false,
      },
      isFullHeight: true,
    },
  };

  return teaser;
}

function mapToTextOnImageTeaser(
  teaserData: ArticleTeaserData | PlaceholderTeaserData,
  { hasIntroDesktop, hasIntroMobile }: AreaOneOptions,
  { isPositionTop }: TeaserOptions,
) {
  const teaser: GTextOnImageTeaser = {
    type: 'text-on-image-teaser',
    data: {
      teaserData,
      hasIntro: false,
      hasDateTime: false,
      isPriority: true,
      headerLevel: 2,
      imageSizes: isPositionTop ? { sm: '100vw', lg: '848px' } : { sm: '100vw', lg: '632px' },
      size: 'lg',
      imageFormat: isPositionTop ? { xs: 'oneOne', lg: defaultTeaserImageRatio } : 'oneOne',
    },
  };
  return teaser;
}

function createItemGridProps(
  item: TTeaserGridItem<string, unknown>,
  { hasGroupTitle }: BaseParams,
  { isPositionMiddle, isDefaultTeaser }: TeaserOptions,
): TGridProps {
  return {
    ...item.gridProps,
    column: 'span 2',
    columnMd: 'span 2',
    columnLg: isPositionMiddle ? '2 / span 2' : '1 / span 2',
    rowLg: `${hasGroupTitle ? 2 : 1} / span 2`,
    hasNoGridDivider: true,
    hasInverseMarginInlineSm: true,
    hasInverseMarginInlineMd: true,
    height: isDefaultTeaser ? undefined : 'fit-content',
  };
}
