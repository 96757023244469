import { type TeaserDataOrList, isTeaserList } from '@hubcms/domain-teaser';

export const flattenArticleLists = (teasers: TeaserDataOrList[]): TeaserDataOrList[] => {
  return teasers
    .map(teaserDataOrList => {
      if (!teaserDataOrList) {
        return [];
      }

      if (!isTeaserList(teaserDataOrList) || teaserDataOrList.listType !== 'article-list') {
        return [teaserDataOrList];
      }

      return teaserDataOrList.items;
    })
    .flat();
};
