import { enableMySection } from '@hubcms/brand';
import type { TTeaserGrid } from '@hubcms/domain-teaser-grid';

import { mapRegularGroup } from './mappers/mapRegularGroup';
import { mapVisualStoriesGroup } from './mappers/mapVisualStoriesGroup';
import { mapLifestyleGroup } from './mappers/mapLifestyleGroup';
import { mapOpeningGroup } from './mappers/mapOpeningGroup';
import { mapHtmlGroup } from './mappers/mapHtmlGroup';
import { mapOpinionGroup } from './mappers/mapOpinionGroup';
import { mapBreakingGroup } from './mappers/mapBreakingGroup';
import { mapArticleGroup } from './mappers/mapArticleGroup';
import { mapToCustomizableStoryblockGroup } from './mappers/CustomizableStoryblockGroup';
import { mapMySectionGroup } from './mappers/mapMySectionGroup';
import type { MapStoryblockFn } from '../domain/map-storyblock-fn';

const EMPTY_TEASER_GRID: TTeaserGrid = { items: [] };

export const mapStoryblock: MapStoryblockFn = (storyblockOptions, teaserAreas) => {
  switch (storyblockOptions.type) {
    case 'RegularGroup':
    case 'SponsoredGroup':
      return mapRegularGroup(storyblockOptions, teaserAreas);
    case 'VisualStoriesGroup':
      return mapVisualStoriesGroup(storyblockOptions, teaserAreas);
    case 'LifestyleGroup':
      return mapLifestyleGroup(storyblockOptions, teaserAreas);
    case 'OpeningGroup':
      return mapOpeningGroup(storyblockOptions, teaserAreas);
    case 'HtmlGroup':
      return mapHtmlGroup(storyblockOptions, teaserAreas);
    case 'OpinionGroup':
      return mapOpinionGroup(storyblockOptions, teaserAreas);
    case 'BreakingGroup':
      return mapBreakingGroup(storyblockOptions, teaserAreas);
    case 'ArticleGroup':
    case 'SponsoredAsideGroup':
      return mapArticleGroup(storyblockOptions, teaserAreas);
    case 'CustomizableGroup':
      return mapToCustomizableStoryblockGroup(storyblockOptions, teaserAreas);
    case 'MySectionGroup':
      if (enableMySection) {
        return mapMySectionGroup(storyblockOptions, teaserAreas);
      }
      return EMPTY_TEASER_GRID;
    default: {
      return EMPTY_TEASER_GRID;
    }
  }
};
