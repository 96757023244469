import type { OpinionGroupOptions } from '@hubcms/domain-storyblock';
import type { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createOpinionGroupOptions: CreateStoryblockOptionsFn<OpinionGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'OpinionGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
    title: groupOptions['groupTitle'],
    actionLink: getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink'),
    actionText: sectionParams['section.group.readmore.text'],
  };
};
