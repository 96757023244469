import type { IDidomiObject } from '@didomi/react';

import { getDidomiAsync, getDidomiSync } from './get-didomi';

export async function getDidomiRequiredPurposeIdsAsync(): Promise<string[]> {
  const Didomi = await getDidomiAsync();

  return getDidomiRequiredPurposeIds(Didomi);
}

export function getDidomiRequiredPurposeIdsSync(): string[] | undefined {
  const Didomi = getDidomiSync();

  if (typeof Didomi === 'undefined') {
    return undefined;
  }

  return getDidomiRequiredPurposeIds(Didomi);
}

export function getDidomiRequiredPurposeIds(Didomi: IDidomiObject): string[] {
  return Didomi.getRequiredPurposeIds();
}
