import type { EnrichedAnnotation } from '@hubcms/domain-story-elements';

export function getLineBreakAnnotations(originalText: string): EnrichedAnnotation[] {
  // @ts-expect-error TS2802
  const breakLineIndexes = [...originalText.matchAll(/\n/g)].map(match => match.index as number).filter(idx => idx !== undefined);
  return breakLineIndexes.map(idx => ({
    start: idx,
    end: idx + 1,
    name: 'lineBreak',
  }));
}
