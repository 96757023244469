import type { GTitle } from './title';

export function createTitle(text: string, linkUrl?: string, linkText?: string): GTitle | null {
  if (!text) {
    return null;
  }
  const gridTitle: GTitle = {
    type: 'title',
    data: {
      text,
      link: linkUrl
        ? {
            text: linkText ?? '',
            url: linkUrl,
            target: '_self',
          }
        : null,
    },
    gridProps: {
      column: '1 / -1',
    },
  };

  return gridTitle;
}
