import type { ArticleTeaserData } from './article-teaser-data';
import type { HtmlTeaserData } from './html-teaser-data';
import type { NewsletterTeaserData } from './newsletter-teaser-data';
import type { PlaceholderTeaserData } from './placeholder-teaser-data';
import type { RssListTeaserData } from './rss-list-teaser-data';

export type TeaserData = ArticleTeaserData | PlaceholderTeaserData | HtmlTeaserData | RssListTeaserData | NewsletterTeaserData;

export function isArticleTeaserData(teaserData?: TeaserData | null): teaserData is ArticleTeaserData {
  return !!teaserData && teaserData.teaserType === 'article';
}

export function isHtmlTeaserData(teaserData?: TeaserData | null): teaserData is HtmlTeaserData {
  return !!teaserData && teaserData.teaserType === 'html';
}

export function isPlaceholderTeaserData(teaserData?: TeaserData | null): teaserData is PlaceholderTeaserData {
  return !!teaserData && teaserData.teaserType === 'placeholder';
}

export function isRssListTeaserData(teaserData?: TeaserData | null): teaserData is RssListTeaserData {
  return !!teaserData && teaserData.teaserType === 'rss-list';
}

export function isNewsletterTeaserData(teaserData?: TeaserData | null): teaserData is NewsletterTeaserData {
  return !!teaserData && teaserData.teaserType === 'newsletter';
}
