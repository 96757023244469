import type { IDidomiObject } from '@didomi/react';

import { getDidomiAsync, getDidomiSync } from './get-didomi';
import { getDidomiRequiredPurposeIds } from './get-didomi-required-purpose-ids';

export async function isDidomiConsentEnabledByPurposeIdAsync(purposeIds?: string | string[]): Promise<boolean> {
  const Didomi = await getDidomiAsync();

  return isDidomiConsentEnabledByPurposeId(Didomi, purposeIds);
}

export function isDidomiConsentEnabledByPurposeIdSync(purposeIds?: string | string[]): boolean | undefined {
  const Didomi = getDidomiSync();

  if (typeof Didomi === 'undefined') {
    return undefined;
  }

  return isDidomiConsentEnabledByPurposeId(Didomi, purposeIds);
}

export function isDidomiConsentEnabledByPurposeId(
  Didomi: IDidomiObject,
  purposeIds: string | string[] = getDidomiRequiredPurposeIds(Didomi),
): boolean {
  const enabledPurposeIds = Didomi.getUserStatus().purposes.consent.enabled.map(purposeId => purposeId.toString());

  if (!Array.isArray(purposeIds)) {
    return enabledPurposeIds.includes(purposeIds);
  }

  return purposeIds.every(purposeId => enabledPurposeIds.includes(purposeId));
}
