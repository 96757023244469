import type { GLogo } from './title';

export function createLogo({ url, alt, text }: { url: string; alt?: string; text?: string }): GLogo {
  const gridTitle: GLogo = {
    type: 'logo',
    data: {
      text,
      logo: {
        url,
        alt: alt ?? '',
      },
    },
    gridProps: {
      column: '1 / -1',
      display: 'block',
      displayLg: 'block',
      displayMd: 'block',
    },
  };

  return gridTitle;
}
