import { sponsoredContentTeaserIsHighlighted, storyblockTeaserSizesConfig } from '@hubcms/brand';

import { isHtmlTeaserData, isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { type GDefaultTeaser, type GHtml, type GTextOnImageTeaser, createTitle } from '@hubcms/domain-teaser-grid';
import type { LifestyleGroupOptions } from '@hubcms/domain-storyblock';

import { getTeaserSize } from '../getTeaserSize';
import { createHtmlGridItem } from '../createHtmlGridItem';
import { getGroupLink } from '../getGroupLink';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';
import { isHtmlOrArticleOrPlaceholderTeaserData } from '../../internal/isArticleOrPlaceholderTeaserData';

const getLifestyleTeaserSizes = getTeaserSize(storyblockTeaserSizesConfig, 'lifestyle');

export const mapLifestyleGroup: MapStoryblockFn<LifestyleGroupOptions> = (storyblockOptions, teaserAreas) => {
  const groupOptions = storyblockOptions.legacyGroupOptions;

  const numberOfTeasers = 5;
  const isSponsoredBlock = groupOptions.groupTheme === 'sponsored-content';
  const groupLink = getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink');
  const title = createTitle(groupOptions['groupTitle'], groupLink, storyblockOptions.actionText);

  const items: Array<GHtml | GTextOnImageTeaser | GDefaultTeaser> = teaserAreas.groupArea
    .filter(isHtmlOrArticleOrPlaceholderTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx) => {
      if (isHtmlTeaserData(teaserData)) {
        return createHtmlGridItem(teaserData);
      }
      const isPlaceholder = isPlaceholderTeaserData(teaserData);
      const isSponsoredTeaser = !isPlaceholder && teaserData.theme === 'sponsored-content';
      const hasSponsoredThemeOnTeaserLevel = !isSponsoredBlock && isSponsoredTeaser;
      const isHighlighted = hasSponsoredThemeOnTeaserLevel && sponsoredContentTeaserIsHighlighted;
      const isHeadTeaser = idx === 0;
      if (isHeadTeaser) {
        return {
          type: 'text-on-image-teaser',
          data: {
            hasLabel: true,
            teaserData,
            imageFormat: 'oneOne',
            imageSizes: {
              sm: '100vw',
              lg: '632px',
            },
            hasIntro: false,
            isPriority: true,
            headerLevel: 3,
            hasDateTime: false,
            theme: hasSponsoredThemeOnTeaserLevel ? 'sponsored-content' : undefined,
            isHighlighted,
            hasInset: isHighlighted,
            size: getLifestyleTeaserSizes(true),
            trackingData: {
              ...(!isPlaceholder ? teaserData.trackingData : {}),
              clickitemblock: groupOptions['groupTitle'],
              clickitemposition: idx + 1,
            },
          },
          gridProps: {
            columnMd: '1 / 3',
            columnLg: '2 / 4',
            rowLg: title ? '2 / 4' : '1 / 3',
            hasInverseMarginInlineSm: true,
            hasInverseMarginInlineMd: true,
            hasInverseMarginSm: isHighlighted,
            hasNoGridDivider: true,
            height: 'fit-content',
          },
        };
      }

      return {
        type: 'default-teaser',
        data: {
          teaserData,
          hasLabel: true,
          imageSizes: {
            md: '480px',
            lg: '308px',
          },
          hasIntro: false,
          isPriority: false,
          headerLevel: 3,
          hasDateTime: false,
          isHighlighted,
          hasInset: isHighlighted,
          orientation: {
            xs: 'horizontal',
            md: 'vertical',
          },
          size: getLifestyleTeaserSizes(isHeadTeaser),
          trackingData: {
            ...(!isPlaceholder ? teaserData.trackingData : {}),
            clickitemposition: idx + 1,
          },
        },
        gridProps: {
          hasInverseMarginSm: isHighlighted,
        },
      };
    });

  return {
    key: 'groupArea',
    gridAutoFlowLg: 'column',
    gridAutoRows: 'min-content',
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
    title,
    items,
  };
};
