import { useMemo } from 'react';
import { usePersonalizedArticleListLoader } from '@hubcms/feature-personalized-article-list';
import { type TeaserPropsOptions, type TeaserData, type TeaserDataOrList, isNonTeaserList } from '@hubcms/domain-teaser';
import { mapRecord } from '@hubcms/utils-browser';
import type { StoryblockAreas } from '@hubcms/domain-storyblock';

import { useArticleListLoader } from '../utils/useArticleListLoader';

export function useFlattenTeaserLists<A extends StoryblockAreas<TeaserDataOrList>>(
  areas: A,
  teaserPropsOptions: TeaserPropsOptions,
): StoryblockAreas<TeaserData> {
  // this structure is required, because we cannot run hooks in a for-loop
  const a1 = useArticleListLoader(areas);
  const a2 = usePersonalizedArticleListLoader(a1, teaserPropsOptions);

  return useMemo(() => mapRecord(a2, area => area.filter(isNonTeaserList)), [a2]);
}
