import { type TeaserFragment, isArticleTeaserContent } from '@hubcms/domain-cook';

export function hasTeaserHeroVideo(teaserData: TeaserFragment): boolean {
  if (!isArticleTeaserContent(teaserData.content)) {
    return false;
  }

  const heroMedia = teaserData.content.heroMedia?.[0];
  if (heroMedia?.content?.__typename === 'Video') {
    return !!heroMedia.content?.fields?.duration;
  }

  return false;
}
