import type { SportsWidgetStoryElement } from '@hubcms/domain-story-elements';
import type { ElementDataMapFn } from './types';
import { extractElementFields } from '../element-renderer';

export const mapSportsWidget: ElementDataMapFn<SportsWidgetStoryElement> = data => {
  const { data: sportifyData, config } = extractElementFields<{ config: string; data: string }>(data);

  if (!sportifyData || !config) {
    return null;
  }

  return {
    id: data.id,
    config: JSON.parse(config),
    data: JSON.parse(sportifyData),
  };
};
