import { useEffect, useState } from 'react';

import type { StoryblockAreas, StoryblockOptions } from '@hubcms/domain-storyblock';
import { mySectionUndeskTeaserFragments } from '@hubcms/feature-my-section';
import type { TeaserDataOrList, TeaserPropsOptions } from '@hubcms/domain-teaser';

const featurePerGroupType = (storyblockOptions: StoryblockOptions) => {
  if (storyblockOptions.type === 'MySectionGroup') {
    // This casting is safe because the typecheck above confirms that we're dealing with a MySectionGroup
    return mySectionUndeskTeaserFragments(storyblockOptions);
  }
  return null;
};

export const useUndeskedTeaserFragments = (
  deskedTeaserAreas: StoryblockAreas<TeaserDataOrList>,
  deskedGroupOptions: StoryblockOptions,
  teaserPropsOptions: TeaserPropsOptions,
) => {
  const [teaserAreas, setTeaserAreas] = useState(deskedTeaserAreas);
  const [storyblockOptions, setStoryblockOptions] = useState<StoryblockOptions>(deskedGroupOptions);

  // Here, a feature can change the teaser areas and group options when needed (e.g. feature my-section)
  useEffect(() => {
    const featureUndeskFn = featurePerGroupType(deskedGroupOptions);
    if (featureUndeskFn) {
      const { newTeaserAreas, newGroupOptions } = featureUndeskFn(deskedTeaserAreas, teaserPropsOptions);
      if (newTeaserAreas) {
        setTeaserAreas(newTeaserAreas);
      }
      if (newGroupOptions) {
        setStoryblockOptions(current => ({ ...current, ...newGroupOptions }));
      }
    }
  }, [deskedTeaserAreas, deskedGroupOptions, teaserPropsOptions]);

  return {
    teaserAreas,
    storyblockOptions,
  };
};
