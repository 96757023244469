import type { AbstractGroup } from '@hubcms/domain-cook';
import type { ArticleGroupOptions } from '@hubcms/domain-storyblock';
import type { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';
import { getListLayout } from '../getListLayout';

export const createArticleGroupOptions: CreateStoryblockOptionsFn<ArticleGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: getType(group.__typename),
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
    title: groupOptions['groupTitle'],
    actionLink: getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink'),
    actionText: sectionParams['section.group.readmore.text'],
    hasImages: groupOptions.webStoryblock === 'TeasersWithImage',
    isListWithMobileSlider: groupOptions['mobileAppSlider'] === 'true',
    listLayout: getListLayout(groupOptions['teaserLayout']),
  };
};

function getType(__typename: AbstractGroup['__typename']): ArticleGroupOptions['type'] {
  if (__typename === 'ArticleGroup' || __typename === 'SponsoredAsideGroup') {
    return __typename;
  }
  return 'ArticleGroup';
}
