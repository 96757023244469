import { useMemo } from 'react';

import { mapRecord } from '@hubcms/utils-browser';
import type { TeaserDataOrList } from '@hubcms/domain-teaser';
import type { StoryblockAreas } from '@hubcms/domain-storyblock';
import { flattenArticleLists } from './flattenArticleLists';

export const useArticleListLoader = (teaserAreas: StoryblockAreas<TeaserDataOrList>): StoryblockAreas<TeaserDataOrList> => {
  return useMemo(() => {
    return mapRecord(teaserAreas, flattenArticleLists);
  }, [teaserAreas]);
};
