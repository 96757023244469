import {
  type AudioVisualArticle,
  type ExtraPageData,
  type IndexPageTagData,
  type TextualArticle,
  isContextWithElements,
} from '@hubcms/domain-cook';

type TagIntroData = NonNullable<IndexPageTagData['intro']>[number];
export type StoryDataWithElements = TextualArticle['context'] | ExtraPageData['context'] | TagIntroData;
export type StoryCookData = StoryDataWithElements | AudioVisualArticle['context'];

export function isTagIntroData(articleData: StoryCookData): articleData is TagIntroData {
  return !!articleData && (articleData.__typename === 'TagIntro' || articleData.__typename === 'TagIntroB2B');
}

export function isStoryDataWithElements(articleData: StoryCookData | null): articleData is StoryDataWithElements {
  if (articleData === null) {
    return false;
  }
  return isTagIntroData(articleData) || isContextWithElements(articleData);
}
