// Explicitly adding the functionality properties here because when building, these refer to global.d.ts on React and those are empty interfaces
interface HTMLElement extends Node {
  querySelectorAll: (selector: string) => NodeListOf<HTMLElement>;
  checkVisibility?: () => boolean;
  getAttribute: (attributeName: string) => string | null;
}

export function getArticleIdsOnBody(body: HTMLElement): string[] {
  return Array.from(body.querySelectorAll('[data-article-id]:not([data-list-id])'))
    .filter(element => !('checkVisibility' in element) || element.checkVisibility?.())
    .map(element => element.getAttribute('data-article-id') || '')
    .filter((element, idx, arr) => element !== '' && arr.indexOf(element) === idx);
}
