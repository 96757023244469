import type { TGridProps, TTeaserGrid } from '@hubcms/domain-teaser-grid';

export function hideGridItemOnMobile(gridProps?: TGridProps): TGridProps {
  return {
    ...removeGridItemDivider(gridProps),
    display: 'none',
    displayMd: 'block',
  };
}

export function removeGridItemDivider(gridProps?: TGridProps): TGridProps {
  return {
    ...gridProps,
    hasNoGridDivider: true,
  };
}

export function hideGridOnMobile(gridData: TTeaserGrid): TTeaserGrid {
  return {
    ...gridData,
    isHiddenSm: true,
    isHiddenMd: false,
  };
}
