import type { FC } from 'react';

import { Ad } from '@hubcms/feature-ads';
import type { StoryblockOptions, TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { Storyblock } from '@hubcms/ui-storyblock';
import { mySectionTeaserGridExtensions } from '@hubcms/feature-my-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { useStoryblockListItem } from '../utils/useStoryblockListItem';
import { useUndeskedTeaserFragments } from '../utils/useUndeskedTeaserFragments';

type StoryblockListItemProps = Pick<TStoryblock, 'theme'> & {
  hasNoPaddingInline?: boolean;
  isTransparentTheme?: boolean;
  groupId: string;
  storyblockOptions: StoryblockOptions;
  teaserGrid: Pick<TStoryblock, 'createGridData' | 'unflattenedTeaserAreas'> & {
    gridExtensions?: TeaserGridExtensions;
  };
  teaserPropsOptions: TeaserPropsOptions;
};

const featureGridExtensions = {
  ...mySectionTeaserGridExtensions,
};

export const StoryblockListItem: FC<StoryblockListItemProps> = ({
  theme,
  groupId,
  storyblockOptions: deskedGroupOptions,
  hasNoPaddingInline,
  isTransparentTheme,
  teaserGrid: { createGridData, unflattenedTeaserAreas: deskedTeaserAreas, gridExtensions },
  teaserPropsOptions,
}) => {
  const { teaserAreas, storyblockOptions } = useUndeskedTeaserFragments(
    deskedTeaserAreas,
    deskedGroupOptions,
    teaserPropsOptions,
  );
  const { gridData } = useStoryblockListItem(teaserAreas, storyblockOptions, createGridData, teaserPropsOptions);

  if (!gridData) {
    return null;
  }

  return (
    <Storyblock
      groupId={groupId}
      theme={theme}
      isTransparentTheme={isTransparentTheme}
      gridData={gridData}
      adElement={Ad}
      hasNoPaddingInline={hasNoPaddingInline}
      gridExtensions={{ ...gridExtensions, ...featureGridExtensions }}
      groupType={deskedGroupOptions.type}
    />
  );
};
