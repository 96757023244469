import {
  type AdditionalTeaserContent,
  type ImageFieldsFragment,
  isAdditionalTeaserContent,
  isArticleTeaserContent,
  isRealTeaserContent,
  isTagIntroTeaserContent,
  type PictureRelationFragment,
  type RealTeaserContent,
  type TeaserFragment,
} from '@hubcms/domain-cook';
import type { TeaserImage, TeaserImageSource } from '@hubcms/domain-teaser';

type CookImageData = {
  caption?: string;
  href?: string;
  content: PictureRelationFragment | null;
};

export function getTeaserImage(teaserFragment: TeaserFragment): TeaserImage | null {
  const cookImageAndCaption = getCookImageAndCaption(teaserFragment);
  if (!cookImageAndCaption || !cookImageAndCaption.image.content) {
    return null;
  }

  const teaserImage: TeaserImage = {
    caption: cookImageAndCaption.caption,
    imageFormats: createImageFormats(cookImageAndCaption.image.content.fields),
  };
  return teaserImage;
}

type ContentWithTeaserImage = RealTeaserContent | AdditionalTeaserContent;

function isContentWithTeaserImage(content: TeaserFragment['content']): content is ContentWithTeaserImage {
  return isRealTeaserContent(content) || isAdditionalTeaserContent(content);
}

function getCookImageAndCaption(teaserFragment: TeaserFragment): {
  image: CookImageData;
  caption: string | null;
} | null {
  const deskedImage = teaserFragment.deskedImage?.[0];

  const teaserImage = isContentWithTeaserImage(teaserFragment.content) ? teaserFragment.content.teaserImage?.[0] : null;
  const firstImage = isTagIntroTeaserContent(teaserFragment.content) ? teaserFragment.content.images?.[0] : null;

  const image = deskedImage || teaserImage || firstImage;

  if (image && !!image.content) {
    const caption = teaserImage?.caption || teaserImage?.content?.fields.caption || null;
    return {
      image,
      caption,
    };
  }

  if (isArticleTeaserContent(teaserFragment.content)) {
    const heroMedia = teaserFragment.content.heroMedia?.[0];
    const heroMediaQuickCaptionObj = heroMedia?.fields?.filter(el => el.name === 'caption')?.pop();
    const heroMediaQuickCaption = heroMediaQuickCaptionObj?.value;

    switch (heroMedia?.content?.__typename) {
      case 'Picture':
        return {
          image: heroMedia as CookImageData,
          caption: heroMediaQuickCaption || heroMedia.content.fields?.caption || null,
        };
      case 'Gallery':
        return heroMedia.content.relatedImages?.[0]
          ? {
              image: heroMedia.content.relatedImages[0],
              caption: heroMediaQuickCaption || heroMedia.content.fields?.title || null,
            }
          : null;
      default:
        break;
    }
  }
  return null;
}

function createImageFormats(fields: NonNullable<CookImageData['content']>['fields']): TeaserImage['imageFormats'] {
  return {
    fourFive: createImageFormat(fields.fourFive),
    fourThree: createImageFormat(fields.fourThree),
    oneOne: createImageFormat(fields.oneOne),
    sixteenNine: createImageFormat(fields.sixteenNine),
    threeTwo: createImageFormat(fields.threeTwo),
    twentyoneNine: createImageFormat(fields.twentyoneNine),
  };
}

function createImageFormat(representation: ImageFieldsFragment): TeaserImageSource {
  return {
    height: representation.height,
    width: representation.width,
    url: representation.href_full,
  };
}
