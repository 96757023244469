import type { GAd } from '@hubcms/domain-teaser-grid';

export function createFullAreaAd(adFormat = ''): GAd {
  return {
    type: 'ad',
    data: {
      adFormat,
      adSlot: 'b',
      isSticky: true,
    },
  };
}
