import type { StoryElement } from './story-element';

export type MapsEmbedData = {
  isValid: boolean;
  latitude: number;
  longitude: number;
  zoom: number;
  mapTypeId: string;
  apiKey: string;
  hasScriptTag?: boolean;
};

export type MapsEmbedStoryElement = StoryElement<'map', MapsEmbedData>;

export function createMapsEmbedData(partialMapsEmbedData: Partial<MapsEmbedData> = {}): MapsEmbedData {
  return {
    isValid: false,
    latitude: 0,
    longitude: 0,
    zoom: 0,
    mapTypeId: '',
    apiKey: '',
    ...partialMapsEmbedData,
  };
}
