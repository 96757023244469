import {
  type ArticleTeaserData,
  type HtmlTeaserData,
  isHtmlTeaserData,
  isPlaceholderTeaserData,
  type PlaceholderTeaserData,
  type NewsletterTeaserData,
  isNewsletterTeaserData,
} from '@hubcms/domain-teaser';
import { sponsoredContentTeaserIsHighlighted } from '@hubcms/brand';

import { createHtmlGridItem } from '../../createHtmlGridItem';
import { createNewsletterItem } from '../../createNewsletterItem';

import { createDefaultTeaserData } from './createDefaultTeaserData';
import type { SubsequentAreaOptions } from './types';

type TeaserOptions = {
  isAreaOnePositionTop: boolean;
  isLastInArea?: boolean;
  isOnlyItem?: boolean;
};

export function createCustomizableStoryblockTeaser(
  teaserData: HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData,
  areaOptions: SubsequentAreaOptions,
  { isAreaOnePositionTop, isLastInArea = false, isOnlyItem = false }: TeaserOptions,
) {
  if (isNewsletterTeaserData(teaserData)) {
    const newsletterTeaser = createNewsletterItem(teaserData);
    newsletterTeaser.gridProps = {
      ...newsletterTeaser.gridProps,
      column: 'span 2',
      columnMd: 'auto',
    };

    return newsletterTeaser;
  }
  const isHtmlTeaser = isHtmlTeaserData(teaserData);
  if (isHtmlTeaser) {
    const htmlTeaser = createHtmlGridItem(teaserData);
    htmlTeaser.gridProps = {
      ...htmlTeaser.gridProps,
      column: 'span 2',
      columnMd: 'auto',
    };
    if (isOnlyItem) {
      htmlTeaser.gridProps = {
        ...htmlTeaser.gridProps,
        rowLg: 'span 2',
      };
    }
    return htmlTeaser;
  }
  const isPlaceholder = isPlaceholderTeaserData(teaserData);
  const hasSponsoredThemeOnTeaserLevel = !isPlaceholder && teaserData.theme === 'sponsored-content';
  const isTeaserHighlighted =
    areaOptions.isHighlighted || (hasSponsoredThemeOnTeaserLevel && sponsoredContentTeaserIsHighlighted);

  return createDefaultTeaserData(teaserData, {
    ...areaOptions,
    isTeaserHighlighted,
    isAreaOnePositionTop,
    isLastInArea,
  });
}
