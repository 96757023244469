import {
  type AdditionalTeaserContent,
  type HtmlTeaserFragment,
  type NewsletterTeaserFragment,
  type RealTeaserContent,
  type TeaserFragment,
  type TeaserOrHtmlBlock,
  isAdditionalTeaserContent,
  isHtmlTeaserFragment,
  isNewsletterTeaserFragment,
  isRealTeaserContent,
  isRssArticleListFragment,
} from '@hubcms/domain-cook';

export type SingleTeaserFragment =
  | TeaserFragment<RealTeaserContent | AdditionalTeaserContent>
  | HtmlTeaserFragment
  | NewsletterTeaserFragment;

export function isSingleTeaserFragment(teaserFragment: TeaserOrHtmlBlock): teaserFragment is SingleTeaserFragment {
  if (!teaserFragment) {
    return false;
  }
  return (
    isHtmlTeaserFragment(teaserFragment) ||
    isRssArticleListFragment(teaserFragment) ||
    isNewsletterTeaserFragment(teaserFragment) ||
    isRealTeaserContent(teaserFragment.content) ||
    isAdditionalTeaserContent(teaserFragment.content)
  );
}
