export const useAdPositions = (positions: number[], adFormats: string[]) => {
  const getAdFormatForPosition = (position: number) => {
    if (position === null) {
      return '';
    }

    const positionIdx = positions.indexOf(position);
    if (positionIdx === -1) {
      return null;
    }

    return adFormats[positionIdx % adFormats.length];
  };

  // Pages have a pageskip parameter instead of direct positions
  const positionsFromPageSkip = positions
    .map(it => it + 1) // It's the number of pages to skip, not the nth page
    .map((it, idx, arr) => it + arr.slice(0, idx).reduce((acc, value) => acc + value, 0));
  const maxPos = Math.max(...positionsFromPageSkip);

  const getAdFormatForPage = (pageIdx: number) => {
    if (pageIdx === null) {
      return '';
    }

    if (pageIdx === 0) {
      return adFormats[0];
    }

    let pos = pageIdx % maxPos;
    if (pos === 0) {
      // Fix to have 11 % 11 be 11 instead of 0
      pos = maxPos;
    }
    const positionIdx = positionsFromPageSkip.indexOf(pos);
    if (positionIdx === -1) {
      // No ad on this position
      return null;
    }
    const nrOfLoops = Math.floor(pageIdx / maxPos);
    const adNumber = positionsFromPageSkip.length * nrOfLoops + positionIdx + 1;

    return adFormats[adNumber % adFormats.length];
  };

  return {
    getAdFormatForPosition,
    getAdFormatForPage,
  };
};
