import type { ArticleTeaserData } from './article-teaser-data';
import type { PartialRequiredTeaser } from '../util-types/partial-required-teaser';

export type RssListTeaserData = {
  teaserType: 'rss-list';
  id: string;
  title: string;
  logo: {
    url: string;
    alt: string;
  };
  items: ArticleTeaserData[];
};

export function createRssTeaserListData(
  partialRssListTeaserData: PartialRequiredTeaser<RssListTeaserData, 'id' | 'title' | 'logo'>,
): RssListTeaserData {
  return {
    teaserType: 'rss-list',
    items: [],
    ...partialRssListTeaserData,
  };
}
