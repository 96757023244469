import type { RelatedArticleData, RelationStoryElement } from '@hubcms/domain-story-elements';
import {
  type ArticleRelationItem,
  type ImageFieldsFragment,
  type LinkableArticleRelationItem,
  type OnHtmlBlock,
  type PictureRelationFragment,
  isElementWithRelation,
} from '@hubcms/domain-cook';
import type { TeaserImage, TeaserImageSource } from '@hubcms/domain-teaser';
import type { ElementDataMapFnWithOptions } from './types';

const PREFIX_FALLBACK = 'ALSO READ.';

// eslint-disable-next-line camelcase
function mapImageFormat({ width, height, href_full }: ImageFieldsFragment): TeaserImageSource {
  return {
    width,
    height,
    // eslint-disable-next-line camelcase
    url: href_full,
  };
}

function mapImageFormats(fields: PictureRelationFragment['fields']): TeaserImage['imageFormats'] {
  return {
    oneOne: mapImageFormat(fields.oneOne),
    fourFive: mapImageFormat(fields.fourFive),
    fourThree: mapImageFormat(fields.fourThree),
    sixteenNine: mapImageFormat(fields.sixteenNine),
    threeTwo: mapImageFormat(fields.threeTwo),
    twentyoneNine: mapImageFormat(fields.twentyoneNine),
  };
}

function getRelatedImage(relation: Exclude<ArticleRelationItem, null>): TeaserImage | null {
  if ('teaserImage' in relation && relation.teaserImage.length > 0) {
    const teaserImage = relation.teaserImage[0];
    if (teaserImage.content) {
      return {
        caption: teaserImage.caption,
        imageFormats: mapImageFormats(teaserImage.content.fields),
      };
    }
  }
  if ('heroMedia' in relation && relation.heroMedia.length > 0) {
    const firstHeroMedia = relation.heroMedia[0];
    if (firstHeroMedia.content?.type === 'picture') {
      return {
        caption: firstHeroMedia.content.fields.caption,
        imageFormats: mapImageFormats(firstHeroMedia.content.fields),
      };
    }
    if (firstHeroMedia.content?.type === 'gallery') {
      const firstGalleryImage = firstHeroMedia.content.relatedImages.at(0);
      if (firstGalleryImage) {
        return {
          caption: firstGalleryImage.content.fields.caption,
          imageFormats: mapImageFormats(firstGalleryImage.content.fields),
        };
      }
    }
  }
  return null;
}

export const mapRelation: ElementDataMapFnWithOptions<'sectionParams', RelationStoryElement> = (data, { sectionParams }) => {
  const prefix = sectionParams['article.related.readmore.text'] || PREFIX_FALLBACK;

  if (isElementWithRelation<OnHtmlBlock>(data) && data.relation?.__typename === 'HtmlBlock') {
    return {
      type: 'code_block',
      htmlBlock: data.relation?.htmlBlock || '',
    };
  }

  if (!isElementWithRelation<LinkableArticleRelationItem>(data)) {
    return null;
  }

  const title = data.relation?.title;
  const url = data.relation?.href;
  const id = data.relation?.id;
  const image = data.relation ? getRelatedImage(data.relation) : null;

  return createRelatedArticleData({
    prefix,
    title,
    url,
    id,
    ...(image ? { image } : null),
  });
};

function createRelatedArticleData({
  prefix = '',
  title = '',
  url = '',
  id = '',
  image,
}: Partial<RelatedArticleData> = {}): RelatedArticleData {
  return {
    prefix,
    title,
    url,
    id,
    ...(image ? { image } : null),
  };
}
