import { useState, useCallback, type MouseEvent } from 'react';

import type { GTitle } from '@hubcms/domain-teaser-grid';
import type { SectionParams } from '@hubcms/domain-cook';

import { updateMySectionCookies } from './updateMySectionCookies';
import { getSectionPreference } from './getSectionPreference';
import { getMySectionOverviewConfiguration } from './getMySectionOverviewConfiguration';

export function useMySectionStoryblockSubtitle(
  title: GTitle | null | undefined,
  sectionName: string,
  subSectionUrl: string,
  sectionParams: SectionParams,
): GTitle | null {
  const [preference, setPreference] = useState(getSectionPreference(sectionParams['mysection.overviewpage']));
  const isMySectionSubsection = sectionParams['mysection.setasmysection'] && sectionParams['mysection.overviewpage'];

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      // collect
      const mySectionOverviewConfiguration = getMySectionOverviewConfiguration(sectionParams);
      const identifierValue = sectionParams[mySectionOverviewConfiguration.identifierParameter];

      // update cookies
      updateMySectionCookies({
        sectionUrl: mySectionOverviewConfiguration.overviewPageUrl,
        chosenPreferenceUrl: subSectionUrl,
        storeMySectionSelectionInCookie: mySectionOverviewConfiguration.storeMySectionSelectionInCookie,
        type: mySectionOverviewConfiguration.mySectionType,
        identifierLabel: mySectionOverviewConfiguration.identifierLabel,
        identifierValue,
        selectionName: sectionName,
      });

      // update state
      setPreference(subSectionUrl);
    },
    [sectionParams, subSectionUrl, sectionName],
  );

  if (!title) {
    return null;
  }

  const currentSubLinkText = title?.data?.subLink?.text;
  const sublinkMatchesCurrentSectionParams =
    currentSubLinkText === sectionParams['mysection.changemysection'] ||
    currentSubLinkText === sectionParams['mysection.setasmysection'];
  const titleMatchesSection = sectionName.toLowerCase().includes(title?.data?.text?.toLowerCase()); // if section is e.g. region brussels and the title is "brussels"

  if (titleMatchesSection && isMySectionSubsection && (!title.data.subLink || sublinkMatchesCurrentSectionParams)) {
    const currentPageIsMySectionPreference = preference === subSectionUrl;
    if (currentPageIsMySectionPreference) {
      title.data.subLink = {
        text: sectionParams['mysection.changemysection'],
        url: sectionParams['mysection.overviewpage'],
      };
    } else {
      title.data.subLink = {
        text: sectionParams['mysection.setasmysection'],
        onClick,
        url: '#',
      };
    }
  }
  return title;
}
