import { isElementTextField } from '@hubcms/domain-cook';
import type { BaseAnnotatedTextStoryElement } from '@hubcms/domain-story-elements';
import { getLinkElements } from '../annotation';
import { prepareAnnotations } from '../annotation/prepareAnnotations';
import type { ElementDataMapFnWithOptions } from './types';

export const mapAnnotatedElement =
  (fieldName: string): ElementDataMapFnWithOptions<'elements', BaseAnnotatedTextStoryElement> =>
  (data, { elements }) => {
    const textField = data.fields?.find(({ name }) => name === fieldName);
    if (!isElementTextField(textField)) {
      return null;
    }

    const text = textField.value;
    const annotations = textField.annotations ?? [];
    const linkElements = getLinkElements(elements);

    return {
      text,
      annotations: prepareAnnotations(text, annotations, linkElements),
      charCount: text.length,
    };
  };
