import {
  type TeaserData,
  type ArticleTeaserData,
  type PlaceholderTeaserData,
  type HtmlTeaserData,
  isArticleTeaserData,
  isPlaceholderTeaserData,
  isHtmlTeaserData,
} from '@hubcms/domain-teaser';

// These functions can be removed after usage of TS 5.5 as they can be inferred automatically
// writing .filter(isArticleTeaserData(teaserData) || isPlaceholderTeaserData(teaserData)) is more explicit
export function isArticleOrPlaceholderTeaserData(
  teaserData: TeaserData,
): teaserData is ArticleTeaserData | PlaceholderTeaserData {
  return isArticleTeaserData(teaserData) || isPlaceholderTeaserData(teaserData);
}

export function isHtmlOrArticleOrPlaceholderTeaserData(
  teaserData: TeaserData,
): teaserData is HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData {
  return isHtmlTeaserData(teaserData) || isArticleTeaserData(teaserData) || isPlaceholderTeaserData(teaserData);
}
