import cx from 'classnames';
import { Ad } from '../Ad/Ad';

import styles from './cba-ad.module.scss';

type CbaAdProps = {
  className?: string;
};

export function CbaAd({ className }: CbaAdProps) {
  return (
    <div className={cx(styles.cbaAd, className)}>
      <Ad adFormat="cba" adSlot="b" adSlotPrefix="cba" />
      <Ad adFormat="cba" adSlot="b" adSlotPrefix="cba" />
    </div>
  );
}
