import { createPlaceholderTeaserData } from '@hubcms/domain-teaser';

export const createPlaceholders = (amount: number | null) => {
  if (!amount) return [];

  return Array.from({ length: amount }, (_, idx) =>
    createPlaceholderTeaserData({
      id: `placeholder-${idx}`,
    }),
  );
};
