import type { HtmlGroupOptions } from '@hubcms/domain-storyblock';
import { isHtmlTeaserData } from '@hubcms/domain-teaser';
import { createTitle } from '@hubcms/domain-teaser-grid';
import { createHtmlGridItem } from '../createHtmlGridItem';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';

export const mapHtmlGroup: MapStoryblockFn<HtmlGroupOptions> = (storyblockOptions, teaserAreas) => {
  const items = teaserAreas.groupArea.filter(isHtmlTeaserData).map(teaser => {
    return createHtmlGridItem(teaser);
  });

  const title = createTitle(storyblockOptions.title, storyblockOptions.actionLink, storyblockOptions.actionText);

  return {
    key: 'groupArea',
    gridColumnsMd: 1,
    gridColumnsLg: 1,
    title,
    items: items.filter(item => !!item),
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
  };
};
