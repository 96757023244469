const paramsMapping = {
  podcast: 'audio',
  video: 'video.vod',
  liveVideo: 'video.live',
};

const isValidAudioVisualType = (type: string): type is keyof typeof paramsMapping => {
  return ['podcast', 'video', 'liveVideo'].includes(type);
};

export function getAgnoPlayerBrandAndLicense(
  type: string | null,
  sectionParams: Record<string, string>,
  brand?: string,
): { brand: string; license: string } {
  if (!type || !isValidAudioVisualType(type)) {
    return { brand: '', license: '' };
  }

  const brandFallback = sectionParams[`player.${paramsMapping[type]}.web`] ?? '';
  const license = sectionParams[`player.${paramsMapping[type]}.licensekey`] ?? '';

  return { brand: brand ?? brandFallback, license };
}
