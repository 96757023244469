import type { Annotation } from '@hubcms/domain-cook';
import type { EnrichedAnnotation } from '@hubcms/domain-story-elements';

export function enrichAnnotation(annotation: Annotation): EnrichedAnnotation {
  return {
    name: annotation.name,
    value: annotation.value,
    start: annotation.index,
    end: annotation.index + annotation.length,
  };
}
