import type { CodeBlockStoryElement } from '@hubcms/domain-story-elements';

import { getElementField } from '../get-element-field';
import type { ElementDataMapFn } from './types';

export const mapCodeBlock: ElementDataMapFn<CodeBlockStoryElement> = data => {
  return {
    htmlBlock: getElementField<string>(data.fields, 'code') ?? '',
  };
};
