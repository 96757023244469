import type { GGrid, TGridProps } from '@hubcms/domain-teaser-grid';

export function createCbaAd(adFormat = '', gridProps: TGridProps = {}): GGrid {
  return {
    type: 'grid',
    data: {
      hasInlinePadding: {
        xs: true,
        sm: true,
        md: false,
        lg: false,
        xl: false,
      },
      gridColumns: 1,
      gridColumnsMd: 1,
      gridColumnsLg: 1,
      gridAutoRows: '1fr',
      items: [
        {
          type: 'ad',
          data: { adSlot: 'b', adFormat, adSlotPrefix: 'cba' },
        },
        {
          type: 'ad',
          data: { adSlot: 'b', adFormat, adSlotPrefix: 'cba' },
        },
      ],
    },
    gridProps,
  };
}
