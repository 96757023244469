import { type GLegacyTeaser, createTitle } from '@hubcms/domain-teaser-grid';
import type { OpinionGroupOptions } from '@hubcms/domain-storyblock';
import { isArticleTeaserData } from '@hubcms/domain-teaser';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';

export const mapOpinionGroup: MapStoryblockFn<OpinionGroupOptions> = (storyblockOptions, teaserAreas) => {
  const numberOfTeasers = 3;
  const title = createTitle(storyblockOptions.title, storyblockOptions.actionLink, storyblockOptions.actionText);

  const items: GLegacyTeaser[] = teaserAreas.groupArea
    .filter(isArticleTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx) => {
      const isHeadTeaser = idx === 0;
      return {
        type: 'legacy-teaser',
        data: {
          teaserData,
          options: {
            sm: 'opinion',
            hideIntro: false,
          },
          imageFormat: 'oneOne',
          trackingData: {
            ...teaserData.trackingData,
            clickitemblock: storyblockOptions.title,
            clickitemposition: idx + 1,
          },
        },
        gridProps: isHeadTeaser
          ? {
              columnMd: '1 / -1',
              columnLg: 'auto',
              hasInverseMarginInlineSm: true,
              hasInverseMarginInlineMd: true,
            }
          : { hasInverseMarginInlineSm: true },
      };
    });

  return {
    key: 'groupArea',
    gridColumnsLg: 3,
    title,
    items,
    hasNoGridDivider: true,
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
  };
};
