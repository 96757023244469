import {
  type ElementsFragment,
  type OnStoryElementWithRelation,
  type StoryElementTextField,
  type StoryElementBooleanField,
  isElementWithRelation,
} from '@hubcms/domain-cook';

type InternalLink = ElementsFragment &
  OnStoryElementWithRelation<{ id: string; href: string }> & {
    type: 'internal_link';
    fields: [
      StoryElementBooleanField & {
        name: 'newWindow';
      },
      StoryElementBooleanField & {
        name: 'noFollow';
      },
    ];
  };
type ExternalLink = ElementsFragment & {
  type: 'external_link';
  fields: [
    StoryElementTextField & {
      name: 'uri';
    },
    StoryElementBooleanField & {
      name: 'newWindow';
    },
  ];
};

export type AnnotationLinkElement = InternalLink | ExternalLink;

function isInternalLink(element?: ElementsFragment): element is InternalLink {
  return !!element && isElementWithRelation(element) && element.type === 'internal_link';
}

function isExternalLink(element?: ElementsFragment): element is ExternalLink {
  return !!element && element.type === 'external_link';
}

export function isAnnotationLinkElement(element?: ElementsFragment): element is AnnotationLinkElement {
  return isInternalLink(element) || isExternalLink(element);
}
