import cx from 'classnames';
import { type ComponentProps, type ElementType, Fragment } from 'react';

import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import type { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { Ad, CbaAd } from '@hubcms/feature-ads';
import { isCbaAd, useAdPositions } from '@hubcms/utils-ads';
import { getNavigationVersion } from '@hubcms/utils-navigation';
import { isThemed } from '@hubcms/utils-theme';

import { StoryblockListItem } from '../ui/StoryblockListItem';

import styles from './storyblock-list.module.scss';

type StoryblockListProps = {
  storyblocks: TStoryblock[];
  adPositions?: number[];
  adFormats?: string[];
  adSlotPrefix?: string;
  isAside?: boolean;
  hasTopMargin?: boolean;
  gridExtensions?: TeaserGridExtensions;
  teaserPropsOptions: TeaserPropsOptions;
  getAdElement?: (
    index: number,
  ) => ElementType<Pick<ComponentProps<typeof Ad>, 'adFormat' | 'adSlot' | 'adStickyTop' | 'adSlotPrefix' | 'className'>>;
};

export function StoryblockList({
  storyblocks,
  adPositions = [],
  adFormats = [],
  adSlotPrefix,
  isAside = false,
  hasTopMargin = false,
  gridExtensions,
  teaserPropsOptions,
  getAdElement = () => Ad,
}: StoryblockListProps) {
  const navigationVersion = getNavigationVersion();

  const { getAdFormatForPosition } = useAdPositions(adPositions, adFormats);

  return (
    <div
      className={cx(styles.storyblockList, {
        [styles.withTopMargin]: hasTopMargin,
        [styles.withLastFillsRemainingSpace]: isAside,
      })}
    >
      {storyblocks.map(({ theme, groupId, storyblockOptions, unflattenedTeaserAreas, createGridData }, idx) => {
        const adFormat = getAdFormatForPosition(idx + 1);
        const AdElement = getAdElement(idx);
        const hideOnMobile = storyblockOptions.isHiddenOnMobile;

        return (
          <Fragment key={groupId}>
            <StoryblockListItem
              theme={theme}
              groupId={groupId}
              storyblockOptions={storyblockOptions}
              hasNoPaddingInline={isAside && !isThemed(theme)}
              isTransparentTheme={isAside}
              teaserGrid={{
                unflattenedTeaserAreas,
                createGridData,
                gridExtensions,
              }}
              teaserPropsOptions={teaserPropsOptions}
            />
            {/* increasing adSlot index by 1 because of the leaderboard at the top of the page. also, the ad positions in CUE begin their count at 1 instead of 0. */}
            {adFormat &&
              (isCbaAd(adFormat) ? (
                <CbaAd
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: hideOnMobile,
                  })}
                />
              ) : (
                <AdElement
                  adFormat={adFormat}
                  adSlot="b"
                  adSlotPrefix={adSlotPrefix}
                  // Sticky ad should clear the navigation v2 sticky services header
                  adStickyTop={isAside && navigationVersion === '2' ? 'var(--scale-12)' : undefined}
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: hideOnMobile,
                  })}
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  );
}
