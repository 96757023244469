import { type ElementsFragment, isElementWithChildren } from '@hubcms/domain-cook';
import type {
  InterviewData,
  InterviewStoryElement,
  ParagraphData,
  StoryElement,
  StoryElementType,
} from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';

import { isValidStoryElement } from '../is-valid-story-element';
import type { ElementDataMapFnWithOptions, RecursiveMapFn } from './types';

const VALID_INTERVIEW_ELEMENTS: StoryElementType[] = ['interview_question', 'interview_answer'];
type InterviewItemStoryElement =
  | StoryElement<'interview_question', ParagraphData>
  | StoryElement<'interview_answer', ParagraphData>;
type InterviewItem = InterviewData['items'][number];

export const mapInterview: ElementDataMapFnWithOptions<'elements' | 'recursiveMapFn', InterviewStoryElement> = (
  data,
  { elements, recursiveMapFn },
) => {
  const children = isElementWithChildren(data) ? data.children.map(childId => elements.find(({ id }) => id === childId)) : [];

  const items: InterviewItemStoryElement[] = mapChildElementsToInterviewItems(children.filter(isNonNull), recursiveMapFn);

  if (!items.length) {
    return null;
  }

  return {
    items: items.map(mapInterviewItem),
    charCount: items.map(item => item.charCount || 0).reduce((total, current) => total + current, 0),
  };
};

function mapChildElementsToInterviewItems(
  children: ElementsFragment[],
  recursiveMapFn: RecursiveMapFn,
): InterviewItemStoryElement[] {
  return children.map(recursiveMapFn).filter(isValidStoryElement<InterviewItemStoryElement>(VALID_INTERVIEW_ELEMENTS));
}

function mapInterviewItem(storyElement: InterviewItemStoryElement): InterviewItem {
  if (storyElement.type === 'interview_question') {
    return {
      id: storyElement.id,
      type: 'question',
      props: storyElement.data,
    };
  }
  return {
    id: storyElement.id,
    type: 'answer',
    props: storyElement.data,
  };
}
