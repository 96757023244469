import type { GMySectionCallToAction } from '@hubcms/domain-my-section';
import type { TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';

import { CallToAction } from '../components';

export const callToActionGridItemParser: TeaserGridItemParserFn<GMySectionCallToAction, typeof CallToAction> = item => ({
  Component: CallToAction,
  key: 'call-to-action',
  componentProps: {
    text: item.data.text,
    buttonText: item.data.buttonText,
    buttonLink: item.data.buttonLink,
    trackingData: item.data.trackingData,
  },
});
