import type { PersonalizedArticleList } from '@hubcms/domain-cook';
import { type ListType, type TeaserDataOrList, type TeaserList, isTeaserList } from '@hubcms/domain-teaser';

export const PERSONALIZED_LIST_TYPE: ListType = 'personalized-list';

export type PersonalizedList = TeaserList<PersonalizedArticleList['fields']> & {
  listType: typeof PERSONALIZED_LIST_TYPE;
};

export function isPersonalizedList(teaserDataOrList?: TeaserDataOrList | null): teaserDataOrList is PersonalizedList {
  return isTeaserList(teaserDataOrList) && teaserDataOrList.listType === PERSONALIZED_LIST_TYPE;
}
