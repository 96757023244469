import type { SocialEmbedStoryElement, SocialEmbedTypes } from '@hubcms/domain-story-elements';

import { extractElementFields } from '../element-renderer';

import type { ElementDataMapFnWithOptions } from './types';

export const SOCIAL_NAMES: Record<SocialEmbedTypes, string> = {
  facebook: 'Facebook',
  twitter: 'X',
  instagram: 'Instagram',
  youtube: 'YouTube',
  tiktok: 'TikTok',
  qualifio: 'Qualifio',
  vimeo: 'Vimeo',
  goplay: 'GoPlay',
  vtm: 'Vtm',
  vrt: 'VRT',
};

export const mapSocialEmbed: ElementDataMapFnWithOptions<'sectionParams', SocialEmbedStoryElement> = (
  data,
  { sectionParams },
) => {
  const { embedTypes: embedType, uri } = extractElementFields<{ embedTypes: SocialEmbedTypes; uri: string }>(data);
  const embedGdprConsentMessage = sectionParams['embed.gdpr.consentmessage'] ?? '';
  const embedGdprConsentButtonText = sectionParams['embed.gdpr.consentbuttontext'] ?? '';
  const socialName = SOCIAL_NAMES[embedType];
  const purposeIds: string[] = sectionParams[`embed.gdpr.${embedType}.requiredconsents`]?.split(',') ?? [];

  return {
    uri,
    embedType,
    purposeIds: purposeIds.map(purposeId => purposeId.trim()),
    fallbackMessage: embedGdprConsentMessage.replace(/{{\s*embed_type\s*}}/g, socialName),
    fallbackButtonText: embedGdprConsentButtonText,
  };
};
