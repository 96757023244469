import type { RecommendationsRequest, RecommendationsResponse } from '@hubcms/domain-capitan';

export const getRecommendations = async (
  apiEndpoint: string,
  request: RecommendationsRequest,
): Promise<RecommendationsResponse> => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiEndpoint, requestOptions);

  if (!response.ok) {
    throw Error();
  }

  const result: Promise<RecommendationsResponse> = response.json();

  return result;
};
