import type { GTeaser } from '@hubcms/domain-teaser-grid';
import type { TrackingData } from '@hubcms/domain-tracking';

export function appendTrackingData<T extends GTeaser>(gridItem: T, additionalTrackingData: TrackingData): T {
  return {
    ...gridItem,
    data: {
      ...gridItem.data,
      trackingData: {
        ...gridItem.data.trackingData,
        ...additionalTrackingData,
      },
    },
  };
}
