import type { TeaserDataOrList } from '@hubcms/domain-teaser';

export function isSameTeaserDataOrListRecord(
  areasOne: Record<string, TeaserDataOrList[]>,
  areasTwo: Record<string, TeaserDataOrList[]>,
) {
  const areaOneKeys = Object.keys(areasOne).sort();
  const areaTwoKeys = Object.keys(areasTwo).sort();
  const sameKeys =
    areaOneKeys.length === areaTwoKeys.length && areaOneKeys.every((areaOneKey, i) => areaOneKey === areaTwoKeys[i]);
  if (sameKeys) {
    return areaOneKeys.every(areaOneKey => {
      const areaOneTeaserDataList = [...areasOne[areaOneKey]].sort();
      const areaTwoTeaserDataList = [...areasTwo[areaOneKey]].sort();
      if (areaOneTeaserDataList.length === areaTwoTeaserDataList.length) {
        return areaOneTeaserDataList.every((teaserData, i) => teaserData.id === areaTwoTeaserDataList[i].id);
      }
      return false;
    });
  }
  return false;
}
