import { useMemo } from 'react';

import { useCook } from '@hubcms/data-access-cook';
import type { QueryResultData } from '@hubcms/domain-cook';
import type { StoryblockAreas, StoryblockOptions, TStoryblock } from '@hubcms/domain-storyblock';
import { useMySectionStoryblockSubtitle } from '@hubcms/utils-my-section';
import type { TeaserPropsOptions, TeaserDataOrList } from '@hubcms/domain-teaser';

import { useFlattenTeaserLists } from '../lib/useFlattenTeaserLists';

export function useStoryblockListItem(
  teaserDataOrListAreas: StoryblockAreas<TeaserDataOrList>,
  storyblockOptions: StoryblockOptions,
  createGridData: TStoryblock['createGridData'],
  teaserPropsOptions: TeaserPropsOptions,
) {
  const { sectionParams, resolution } = useCook<QueryResultData>();

  const teaserAreas = useFlattenTeaserLists(teaserDataOrListAreas, teaserPropsOptions);

  const gridData = useMemo(
    () => createGridData(teaserAreas, storyblockOptions),
    [createGridData, teaserAreas, storyblockOptions],
  );

  gridData.title = useMySectionStoryblockSubtitle(
    gridData.title,
    resolution?.section?.name,
    resolution?.section?.href,
    sectionParams,
  );

  const hasTeasersInAreas = Object.values(teaserAreas).some(teaserArea => teaserArea.length > 0);

  if (!hasTeasersInAreas) {
    return { gridData: null };
  }

  return {
    gridData,
  };
}
