import { type TeaserData } from '@hubcms/domain-teaser';
import type { TGridProps, TTeaserGridItem } from '@hubcms/domain-teaser-grid';
import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';

import { hideGridItemOnMobile, removeGridItemDivider } from '../../../internal/hideGridItemOnMobile';
import { createFullAreaAd } from '../../createFullAreaAd';
import { isCustomizableStoryblockTeaserData } from '../../../internal/isCustomizableStoryblockTeaserData';

import type { BaseParams } from './types';
import { createCustomizableStoryblockTeaser } from './createCustomizableStoryblockTeaser';
import { createAreaWithCbaAds } from './createAreaWithCbaAds';

type Params = BaseParams & {
  isAreaThreeHiddenMobile: boolean;
  isAreaThreeStackedMobile: boolean;
  isAreaThreeList: boolean;
  hasAreaThreeTitle: boolean;
  isAreaThreeAnAd: boolean;
};

type AreaTwoOptions = CustomizableGroupOptions['areaTwoOptions'];

export function mapToAreaTwoItems(
  teaserDataList: TeaserData[],
  areaTwoOptions: AreaTwoOptions,
  params: Params,
): Array<TTeaserGridItem<string, unknown>> {
  const isAreaOnePositionTop = params.areaOnePosition === 'top';

  if (areaTwoOptions.hasCbaAd) {
    const cbaAreaItems = createAreaWithCbaAds(
      teaserDataList.filter(isCustomizableStoryblockTeaserData),
      areaTwoOptions,
      isAreaOnePositionTop,
    );
    return cbaAreaItems.map(item => {
      item.gridProps = getTeaserPosition(item.gridProps, params);

      if (areaTwoOptions.isAreaHiddenMobile) {
        item.gridProps = hideGridItemOnMobile(item.gridProps);
      }

      return item;
    });
  }

  if (areaTwoOptions.hasAd) {
    const ad = createFullAreaAd(areaTwoOptions.fullAdFormat);
    ad.gridProps = {
      ...getAdPosition(params.areaOnePosition),
      hasNoGridDivider: true,
    };
    if (areaTwoOptions.isAreaHiddenMobile) {
      ad.gridProps = hideGridItemOnMobile(ad.gridProps);
    }
    return [ad];
  }

  return teaserDataList
    .filter(isCustomizableStoryblockTeaserData)
    .slice(0, 2)
    .map((teaserData, teaserIdx, teaserArray) => {
      const isLastInArea = teaserIdx === teaserArray.length - 1;
      const isOnlyItem = teaserDataList.length === 1;
      const teaser = createCustomizableStoryblockTeaser(teaserData, areaTwoOptions, {
        isAreaOnePositionTop,
        isLastInArea,
        isOnlyItem,
      });
      if (teaser) {
        teaser.gridProps = getTeaserPosition(teaser.gridProps, params);

        if (areaTwoOptions.isAreaHiddenMobile) {
          teaser.gridProps = hideGridItemOnMobile(teaser.gridProps);
        }
        const canRemoveDivider =
          params.isAreaThreeHiddenMobile ||
          params.isAreaThreeList ||
          params.hasAreaThreeTitle ||
          !areaTwoOptions.isStackedMobile ||
          !params.isAreaThreeStackedMobile ||
          params.isAreaThreeAnAd;

        if (canRemoveDivider && isLastInArea) {
          teaser.gridProps = removeGridItemDivider(teaser.gridProps);
        }
        if (areaTwoOptions.isStackedMobile && !params.isAreaThreeStackedMobile && isLastInArea) {
          teaser.gridProps = {
            ...teaser.gridProps,
            hasInverseMarginBlockEndSm: false,
          };
        }
      }

      return teaser;
    });
}

function getTeaserPosition(
  gridProps: TGridProps | undefined,
  { areaOnePosition, hasGroupTitle }: Params,
): TGridProps | undefined {
  if (areaOnePosition === 'top') {
    return {
      ...gridProps,
      rowLg: hasGroupTitle ? '4 / 5' : '3 / 4',
    };
  }
  if (areaOnePosition === 'left') {
    return {
      ...gridProps,
      columnLg: '3 / 4',
    };
  }
  if (areaOnePosition === 'middle') {
    return {
      ...gridProps,
      columnLg: '1 / 2',
    };
  }
  return undefined;
}

function getAdPosition(areaOnePosition: BaseParams['areaOnePosition']): TGridProps {
  if (areaOnePosition === 'left' || areaOnePosition === 'middle') {
    return {
      column: 'span 2',
      columnLg: 'auto',
      rowLg: 'span 2',
    };
  }
  return {
    column: 'span 2',
    columnLg: '1 / 3',
  };
}
