import { useRef, useState, useMemo } from 'react';
import { useResizeObserver, useWindowSize } from 'usehooks-ts';
import cx from 'classnames';

import { Ad, type AdProps } from '../Ad/Ad';

import styles from './scrollable-ads.module.scss';

type ScrollableAdsProps = Pick<AdProps, 'adFormat' | 'adStickyTop' | 'className'>;

export const ScrollableAds = ({ adFormat, adStickyTop, className }: ScrollableAdsProps) => {
  const [height, setHeight] = useState(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { height: viewportHeight } = useWindowSize();
  const { height: wrapperHeight = 0 } = useResizeObserver({
    ref: wrapperRef,
    box: 'border-box',
  });
  const totalAdHeight = height * 2 + viewportHeight;

  const numberOfSlots = useMemo(() => {
    if (wrapperHeight > 0 && totalAdHeight > 0) {
      return Math.max(Math.floor(wrapperHeight / totalAdHeight - 1), 0);
    }
    return 0;
  }, [totalAdHeight, wrapperHeight]);

  const firstAdHeight = useMemo(() => {
    if (wrapperHeight > 0 && numberOfSlots > 0) {
      if (wrapperHeight <= height * 2) {
        return '100%';
      }
      if (wrapperHeight > height * 2 && wrapperHeight < totalAdHeight) {
        return `${height * 2}px`;
      }
      return `${totalAdHeight}px`;
    }
    return '100%';
  }, [height, totalAdHeight, wrapperHeight, numberOfSlots]);

  const lastAdHeight = useMemo(() => {
    if (wrapperHeight > 0) {
      const restHeight = wrapperHeight - numberOfSlots * totalAdHeight;
      return Math.max(restHeight, height);
    }
    return 0;
  }, [numberOfSlots, totalAdHeight, height, wrapperHeight]);

  return (
    <div className={cx(styles.scrollableAds, className)} ref={wrapperRef}>
      <div style={{ height: firstAdHeight }}>
        <Ad
          adFormat={adFormat}
          adSlot="b"
          adSlotPrefix="recirculation"
          adStickyTop={adStickyTop}
          isSticky={true}
          onChangeHeight={setHeight}
          style={{
            height: numberOfSlots > 0 ? `${height * 2}px` : 'unset',
          }}
        />
      </div>
      {Array.from(Array(numberOfSlots).keys()).map((x, i, arr) => {
        const isLastElement = i === arr.length - 1;
        return (
          <div key={`next-ad-${x}`} style={{ height: isLastElement ? `${lastAdHeight}px` : `${totalAdHeight}px` }}>
            <Ad
              adFormat={adFormat}
              adSlot="b"
              adSlotPrefix="recirculation"
              adStickyTop={adStickyTop}
              isSticky={!isLastElement ? true : lastAdHeight > height}
              style={{
                height: isLastElement ? 'unset' : `${height * 2}px`,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
