import { enableMySection } from '@hubcms/brand';

import type { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { GMySectionCallToActionType } from '@hubcms/domain-my-section';
import { callToActionGridItemParser } from '@hubcms/ui-my-section';

export const mySectionTeaserGridExtensions: TeaserGridExtensions = enableMySection
  ? {
      [GMySectionCallToActionType]: callToActionGridItemParser,
    }
  : {};
