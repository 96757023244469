import { type TeaserData, isHtmlTeaserData, isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import type { GDefaultTeaser, GHtml, GListTeaser, TGridProps } from '@hubcms/domain-teaser-grid';
import { sponsoredContentTeaserIsHighlighted } from '@hubcms/brand';

import type { ListOptions } from '../domain/list-options';
import { createHtmlGridItem } from '../lib/createHtmlGridItem';
import { isHtmlOrArticleOrPlaceholderTeaserData } from './isArticleOrPlaceholderTeaserData';

export function createListItems(
  teaserDataList: TeaserData[],
  {
    hasImages,
    listLayout,
    isHighlighted,
    isListWithMobileSlider,
    sliderTeaserOrientation = 'vertical',
    sliderDefaultMinWidth = 240,
  }: ListOptions,
): Array<GDefaultTeaser | GListTeaser | GHtml> {
  return teaserDataList.filter(isHtmlOrArticleOrPlaceholderTeaserData).map(teaserData => {
    if (isHtmlTeaserData(teaserData)) {
      const htmlTeaser: GHtml = createHtmlGridItem(teaserData);
      htmlTeaser.gridProps = addMinWidth(htmlTeaser.gridProps, isListWithMobileSlider, 240);
      return htmlTeaser;
    }

    const isPlaceholder = isPlaceholderTeaserData(teaserData);

    if (!hasImages) {
      const listTeaser: GListTeaser = {
        type: 'list-teaser',
        data: {
          teaserData,
          headerLevel: 5,
          hasDateTime: listLayout === 'PublishedDate',
          trackingData: !isPlaceholder ? teaserData.trackingData : {},
        },
      };
      listTeaser.gridProps = addMinWidth(listTeaser.gridProps, isListWithMobileSlider, 224);
      return listTeaser;
    }
    const isSponsoredHighlighted =
      !isPlaceholder && teaserData.theme === 'sponsored-content' && sponsoredContentTeaserIsHighlighted;
    const defaultTeaser: GDefaultTeaser = {
      type: 'default-teaser',
      data: {
        teaserData,
        hasIntro: false,
        hasDateTime: listLayout === 'PublishedDate',
        orientation: {
          xs: isListWithMobileSlider ? sliderTeaserOrientation : 'horizontal',
          md: 'horizontal',
        },
        size: 'sm',
        hasInset: isHighlighted || isSponsoredHighlighted,
        isPriority: false,
        isHighlighted: isSponsoredHighlighted,
        headerLevel: 5,
        trackingData: !isPlaceholder ? teaserData.trackingData : {},
      },
      gridProps: {
        hasInverseMarginSm: isHighlighted || isSponsoredHighlighted,
      },
    };
    defaultTeaser.gridProps = addMinWidth(defaultTeaser.gridProps, isListWithMobileSlider, sliderDefaultMinWidth);
    return defaultTeaser;
  });
}

function addMinWidth(gridProps: TGridProps | undefined, isListWithMobileSlider: boolean, minWidth: number) {
  if (!isListWithMobileSlider) {
    return gridProps;
  }

  const newGridProps: TGridProps = {
    ...gridProps,
    minWidth: {
      sm: `${minWidth}px`,
    },
  };
  return newGridProps;
}
